import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import get from "lodash/get";
import throttle from "lodash/throttle";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, {
  Component,
  createRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import ReactContentfulImage from "react-contentful-image";
import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";

import ContentModal from "@/components/editorial/content-modal";
import SecondaryHeadline from "@/components/headlines/home/secondary";
import LinkWithArrow from "@/components/links/link-with-arrow-kam";
import ModalWrap from "@/components/modal-wrap";
import { TIMINGS } from "@/constants";
import ImageSizes from "@/utils/helpers/images";
import { getStyleObject } from "@/utils/helpers/module_style_tags";
import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./index.module.scss";

const getClientPosition = (el) => {
  if (!el) {
    return;
  }
  const element = el.getBoundingClientRect();
  const top = element.top - window.scrollY;
  const left = element.left - window.scrollX;
  const { width, height } = element;

  return { top, left, width, height };
};

const EditorialArticleScroller = ({
  articleQuery,
  data,
  isMobile,
  isTablet,
}) => {
  const { articleItems, marginBottom, anchorTag } = data;
  let containerRef = createRef(null);
  const [fromQuery, setFromQuery] = useState(0);
  const [containerLeft, setContainerLeft] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const router = useRouter();
  const closeModal = () => {
    if (fromQuery) {
      const {
        pathname,
        query: { slug },
      } = router;

      router.replace(`${pathname}/${slug}`, { shallow: false });
      setModalOpen(false);
      setFromQuery(false);
    } else {
      setModalOpen(false);
    }
  };
  const selectModal = (content) => {
    if (!content) {
      return;
    }
    setModalOpen(true);
    setModalContent(content);
  };

  const handleResize = useCallback(() => {
    if (!containerRef) {
      return;
    }
    const { left } = getClientPosition(containerRef);
    setContainerLeft(Math.round(left));
  }, [containerRef]);

  useEffect(() => {
    if (typeof window == "undefined") {
      return;
    }

    handleResize();

    if (articleQuery) {
      const articleSearch = articleItems.filter((item) => {
        return articleQuery === item.fields.slug;
      })[0];
      if (articleSearch) {
        const {
          fields: { content },
        } = articleSearch;
        if (content) {
          setModalContent(content);
          setModalOpen(true);
          setFromQuery(true);
        }
      }
    }

    const throttledHandleResize = throttle(
      handleResize,
      TIMINGS.THROTTLE_RESIZE_TIME_OUT
    );

    window.addEventListener("resize", throttledHandleResize);

    return () => {
      window.removeEventListener("resize", throttledHandleResize);
    };
  }, [articleItems, articleQuery, handleResize]);

  return (
    <section
      className={styles.editorialArticleScroller}
      data-attr-scroll={anchorTag}
      id="editorial-scroller"
      style={getStyleObject({ marginBottom })}
    >
      <div
        className="container"
        ref={(e) => {
          containerRef = e;
        }}
      >
        {articleItems &&
          articleItems.map((article, index) => {
            return (
              <EditorialScrollerItem
                data={article}
                imageLeftPosition={containerLeft}
                isMobile={isMobile}
                isTablet={isTablet}
                key={index}
                selectModalFunc={selectModal}
              />
            );
          })}
      </div>
      <ModalWrap
        displayClose={true}
        onRequestClose={closeModal}
        open={modalOpen}
      >
        <ContentModal data={modalContent} onRequestClose={closeModal} />
      </ModalWrap>
    </section>
  );
};

class EditorialScrollerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundImageWidth: 0,
    };
    this.columnLeftRef = null;
    this.handleResize = this.handleResize.bind(this);
    this.setBindings = this.setBindings.bind(this);
    this.unSetBindings = this.unSetBindings.bind(this);
    this.throttledHandleResize = throttle(
      this.handleResize,
      TIMINGS.THROTTLE_RESIZE_TIME_OUT
    );
  }

  handleResize = () => {
    if (!this.columnLeftRef) {
      return;
    }
    const { width } = getClientPosition(this.columnLeftRef);
    this.setState({ backgroundImageWidth: width });
  };

  setBindings = () => {
    window.addEventListener("resize", this.throttledHandleResize);
  };
  unSetBindings = () => {
    window.removeEventListener("resize", this.throttledHandleResize);
  };

  componentDidMount() {
    this.handleResize();
    this.setBindings();
  }

  componentWillUnmount() {
    this.unSetBindings();
  }

  render() {
    const { data, imageLeftPosition, selectModalFunc } = this.props;
    const isMobileOrTabletOrIOS13 = this.props.isMobile || this.props.isTablet;
    const { backgroundImageWidth } = this.state;
    const headerHeight = 80;
    const imagePadding = 2;
    const {
      fields: {
        label,
        title,
        slug,
        DescriptionNew,
        callToActionText,
        content,
        spotifyLink,
      },
    } = data;
    const backgroundImage = get(data, "fields.backgroundImage.fields");
    const backgroundImageMobile = get(
      data,
      "fields.backgroundImageMobile.fields"
    );
    const { contentType } = backgroundImage.file;

    const backgroundImageStyles = {
      backgroundColor: `black`,
      backgroundImage: `url(${backgroundImage.file.url}?${
        contentType !== "image/gif"
          ? ImageSizes.editorialBackgroundPortrait()
          : ""
      })`,
      backgroundPosition: !isMobileOrTabletOrIOS13
        ? `${imageLeftPosition}px ${headerHeight}px`
        : "",
      backgroundSize: !isMobileOrTabletOrIOS13
        ? `${backgroundImageWidth + imagePadding}px`
        : "contain",
      backgroundAttachment: !isMobileOrTabletOrIOS13 ? "fixed" : "scroll",
    };
    return (
      <Controller>
        <section
          className={styles.editorialArticleScrollerItem}
          data-attr-module-name="module-editorial-article-scroller"
          data-attr-scroll={slug}
          style={{
            paddingTop: isMobileOrTabletOrIOS13 && "50px",
            paddingBottom: isMobileOrTabletOrIOS13 && "50px",
          }}
        >
          <>
            <div className={`row`}>
              <div
                className={`${styles.editorialArticleScrollerLeftColumn} col-lg-10 col-md-none`}
                ref={(e) => {
                  this.columnLeftRef = e;
                }}
                style={backgroundImageStyles}
              >
                <ReactContentfulImage
                  alt={backgroundImage.title}
                  aria-hidden={true}
                  sizes={ImageSizes.breakerDesktop}
                  src={backgroundImage.file.url}
                  title={backgroundImage.title}
                />
              </div>
              <div
                className={`${styles.editorialArticleScrollerRightColumn} col-lg-4 offset-lg-1`}
              >
                <Controller>
                  <Scene
                    duration="140%"
                    triggerElement={`[data-attr-scroll="${slug}"]`}
                  >
                    {(progress) => (
                      <Tween
                        from={{
                          css: {
                            top: !isMobileOrTabletOrIOS13 ? "300px" : "0px",
                          },
                        }}
                        paused
                        to={{
                          css: {
                            top: !isMobileOrTabletOrIOS13 ? "-200px" : "0px",
                          },
                        }}
                        totalProgress={progress}
                      >
                        <div
                          className={`${styles.editorialArticleScrollerRightColumnInner}`}
                        >
                          {label && (
                            <span
                              className={`${styles.editorialArticleScrollerEyeBrow}`}
                            >
                              {label}
                            </span>
                          )}
                          <SecondaryHeadline styles={styles} text={title} />
                          <div
                            className={`${styles.editorialArticleScrollerMobileImage}`}
                          >
                            <img
                              alt=""
                              src={backgroundImageMobile.file.url}
                              title={backgroundImageMobile.title}
                            />
                          </div>
                          <div
                            className={`${styles.editorialArticleScrollerContent}`}
                            dangerouslySetInnerHTML={{
                              __html: documentToHtmlString(DescriptionNew),
                            }}
                          />
                          {spotifyLink && (
                            <div
                              className={
                                styles.editorialArticleScrollerContentSpotify
                              }
                            >
                              <iframe
                                allow={"encrypted-media"}
                                frameBorder={0}
                                height={360}
                                src={`https://open.spotify.com/embed?uri=${spotifyLink}`}
                                width={`100%`}
                              />
                            </div>
                          )}
                          {callToActionText && (
                            <div
                              className={
                                styles.editorialArticleScrollerContentCta
                              }
                            >
                              <LinkWithArrow
                                data={{
                                  fields: {
                                    text: callToActionText,
                                  },
                                }}
                                onClickFunc={(e) => {
                                  e.preventDefault();
                                  selectModalFunc(content);
                                }}
                                onKeyPressFunc={(e) => {
                                  if (e.which == 13 || e.keyCode == 13) {
                                    selectModalFunc(content);
                                    return false;
                                  }
                                  return true;
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Tween>
                    )}
                  </Scene>
                </Controller>
              </div>
            </div>
          </>
        </section>
      </Controller>
    );
  }
}

EditorialScrollerItem.propTypes = {
  data: PropTypes.object,
  imageLeftPosition: PropTypes.number,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  selectModalFunc: PropTypes.func,
};

EditorialScrollerItem.defaultPropTypes = {
  data: {},
  isMobile: true,
  isTablet: false,
};

EditorialArticleScroller.propTypes = {
  articleQuery: PropTypes.string,
  data: PropTypes.object,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
};

EditorialArticleScroller.defaultPropTypes = {
  data: {},
  isMobile: true,
  isTablet: false,
};

export default withScreenDimensions(EditorialArticleScroller);
